<template>
  <div>
    <a-form :model="courseGeneralForm" layout="vertical">
      <a-form-item required label="Nama Course">
        <a-input v-model:value="courseGeneralForm.name" />
      </a-form-item>
      <a-form-item label="Deskripsi">
        <a-textarea :rows="6" v-model:value="courseGeneralForm.description"></a-textarea>
      </a-form-item>
      <a-row :gutter="[32,0]">
        <a-col :md="24" :lg="12">
          <a-form-item required label="Vendor/Instruktur">
            <a-select v-model:value="courseGeneralForm.vendorId">
              <a-select-option v-for="vendor in vendors" :key="vendor._id" :value="vendor._id">{{vendor.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :md="24" :lg="12">
          <a-form-item required label="Kategori">
            <a-select v-model:value="courseGeneralForm.categoryId">
              <a-select-option v-for="category in categories" :key="category._id" :value="category._id">{{category.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider class="my-3" orientation="left">Section dan Lesson</a-divider>
      <a-collapse class="mb-3" v-model:activeKey="activeKeySectionAccordion" v-if="sectionList.length">
        <a-collapse-panel v-for="(section, i) in sectionList" :key="String(i)" :header="section.name">
          {{section.lessons}}
          <div class="mb-3" v-if="section.lessons.length">
            <div v-for="(lesson, j) in section.lessons" :key="j" class="d-flex w-100">
              <div>
                <lock-outlined v-if="lesson.isLocked"></lock-outlined>
                <caret-right-outlined v-else></caret-right-outlined>
              </div>
              <div class="ml-3">
                {{lesson.name}}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-100">
              <a-input v-model:value="newLesson[section.key].name"></a-input>
            </div>
            <div class="mx-3 w-100">
              <a-select v-model:value="newLesson[section.key].isLocked">
                <a-select-option value="true">Dikunci</a-select-option>
                <a-select-option value="false">Terbuka</a-select-option>
              </a-select>
            </div>
            <a-button @click.prevent="addLesson(section.key)" type="dashed">
              <plus-outlined /> Buat Lesson
            </a-button>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div class="d-flex justify-content-between">
        <div class="w-100">
          <a-input placeholder="Masukkan nama section..." v-model:value="newSection"></a-input>
        </div>
        <div class="ml-3">
          <a-button @click.prevent="addSection" type="primary">
            <plus-outlined />
          </a-button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { PlusOutlined, LockOutlined, CaretRightOutlined } from '@ant-design/icons-vue';
export default {
  components: { PlusOutlined, LockOutlined, CaretRightOutlined },
  setup() {
    const store = useStore()
    let vendors = ref([])
    let categories = ref([])
    let courseGeneralForm = reactive({
      name: null,
      description: null,
      price: 0,
      type: 'free',
      vendorId: null,
      categoryId: null,
    })

    let sectionList = ref([])

    let newSection = ref(null)

    let newLesson = ref([])

    let activeKeySectionAccordion = ref([])

    const fetchVendors = async () => {
      const rawData = await store.dispatch('vendor/FETCH_VENDOR')
      vendors.value = rawData.map(vendor => {
        return {
          name: vendor.firstName + (vendor.lastName ? ' ' + vendor.lastName : ''),
          ...vendor,
        }
      })
    }

    const fetchCategories = async () => {
      categories.value = await store.dispatch('category/FETCH_CATEGORY')
    }

    onMounted(async () => {
      fetchVendors()
      fetchCategories()
    })

    const addSection = () => {
      // console.log(sectionList.value, vendors.value[0])
      const key = sectionList.value.length
      sectionList.value = sectionList.value.concat([{
        key,
        name: newSection.value,
        lessons: [],
      }])
      newLesson.value.push({
        sectionId: key,
        name: null,
        isLocked: "false",
      })
      newSection.value = null
    }

    const addLesson = (key) => {
      let oldLesson = sectionList.value[key].lessons
      console.log(newLesson.value[key])
      const data = {
        ...newLesson.value[key], isLocked: JSON.parse(newLesson.value[key].isLocked),
      }
      sectionList.value[key].lessons = oldLesson.concat([ data ])
      newLesson.value[key] = {
        name: null,
        isLocked: "false",
      }
    }

    return {
      vendors,
      categories,
      courseGeneralForm,
      newSection,
      addSection,
      sectionList,
      activeKeySectionAccordion,
      newLesson,
      addLesson,
    }
  },
}
</script>

<style>

</style>
