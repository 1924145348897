<template>
  <div class="">
    <h2 class="th-title">Buat Course Baru</h2>
    <a-card>
      <a-row :gutter="[32, 0]">
        <a-col :md="24" :lg="6" class="d-flex">
          <a-steps class="pr-3" direction="vertical" :current="current">
            <a-step v-for="item in steps" :key="item.title" :title="item.title" :description="item.description" />
            <a-step title="Selesai" status="finish">
              <!-- <template #icon>
                <check-circle-outlined />
              </template> -->
            </a-step>
          </a-steps>
          <a-divider class="h-100" type="vertical"></a-divider>
        </a-col>
        <a-col :md="24" :lg="18">
          <div class="d-flex justify-content-between mb-3">
            <h3>{{ steps[current].title }}</h3>
            <a-button type="link">SAVE AS DRAFT</a-button>
          </div>
          <general-information-step v-if="current === 0"></general-information-step>
          <upload-video-step v-else-if="current === 1"></upload-video-step>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { CheckCircleOutlined } from '@ant-design/icons-vue';
import GeneralInformationStep from '@/components/Course/Create/General'
// const GeneralInformationStep = () => import('@/components/Course/Create/General')
import UploadVideoStep from '@/components/Course/Create/Upload'
const steps = [
  {
    title: 'Informasi Umum',
    description: 'Masukkan data course, daftar section dan lesson.',
  },
  {
    title: 'Upload Video',
    description: 'Upload video pada setiap lesson-nya.',
  },
]
export default {
  components: { UploadVideoStep, GeneralInformationStep },
  setup() {
    let store = useStore()
    let courseData = ref([])
    let sortRef = ref(null)
    let filterRef = ref([])
    let pageRef = ref(null)
    let currentTitle = ref('')
    let current = ref(0)

    onMounted(async () => {
      const { value:sort } = sortRef
      const { value: filter } = filterRef
      const { value: page } = pageRef
      courseData = await store.dispatch('course/FETCH_ALL_COURSE', {
        sort, filter, page,
      })

      console.log(courseData)
    })

    const onChangeStep = (data) => {
      console.log(data)
    }

    return {
      courseData,
      sortRef,
      filterRef,
      pageRef,
      currentTitle,
      onChangeStep,
      current,
      steps,
    }
  },
}
</script>
